import React, { Component } from 'react';
import panamericano from './Panamericano_Bitmoji.png';
import banner from './panamericano-polygon-text.png';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">

          <img src={panamericano} className="Panamericano" alt="logo" />
          <img src={banner} className="banner" alt="Panamericano-Polygon-Text" />

        <div className="calltoaction">
          <a href="https://www.youtube.com/watch?v=ZiKq3n1XViw&list=PLwCbMb-X3S5J99BLtOoBWRA4w0bExrQd-" target="_blank" rel="noopener noreferrer">Play Latest Video</a>
          <p className="top">Aventuras a través las avenidas de America</p>
          <p className="bottom">Adventures through the avenues of America</p>
        </div>

        <div className="accounts">
      		<div className="social">
      			<li className="instagram"><a href="https://www.instagram.com/panamericano" target="_blank" rel="noopener noreferrer">Instagram</a></li>
      			<li className="youtube"><a href="https://www.youtube.com/subscription_center?add_user=donjeda" target="_blank" rel="noopener noreferrer">YouTube</a></li>
      			<li className="snapchat"><a href="https://www.snapchat.com/add/panamerican0" target="_blank" rel="noopener noreferrer">Snapchat</a></li>
      			<li className="twitter"><a href="https://twitter.com/intent/user?screen_name=panamerican_" target="_blank" rel="noopener noreferrer">Twitter</a></li>
      			<li className="github"><a href="https://www.github.com/panamericano" target="_blank" rel="noopener noreferrer">Github</a></li>
      			<li className="dribbble"><a href="https://www.dribbble.com/panamericano" target="_blank" rel="noopener noreferrer">Dribbble</a></li>
      			<li className="vine"><a href="https://www.vine.co/panamericano" target="_blank" rel="noopener noreferrer">Vine</a></li>
      			<li className="twitch"><a href="https://www.twitch.tv/pan_americano" target="_blank" rel="noopener noreferrer">Twitch</a></li>
      			<li className="mail"><a href="mailto:contact@panamericano.app" target="_blank" rel="noopener noreferrer">mail</a></li>
      		</div>
        </div>

    </div>
    );
  }
}

export default App;
